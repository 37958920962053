<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-18 11:54:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-25 15:55:55
 * @FilePath: /mediatom-web/src/viewsForManage/user/Register.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="register-container">
    <a-form-model ref="registerForm" :model="form" :rules="{}">
      <!-- 账号 -->
      <a-form-model-item
        :rules="[
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur'
          },
          {
            message: '邮箱必须为邮箱格式',
            trigger: 'blur',
            pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
          }
        ]"
        prop="loginId"
      >
        <a-input type="text" placeholder="请输入注册邮箱" v-model="form.loginId"></a-input>
      </a-form-model-item>
      <!-- 密码 -->
      <a-form-model-item :rules="[{ required: true, trigger: 'blur', validator: validatePass }]" prop="password">
        <a-input
          v-model.trim="form.password"
          ref="password"
          :type="isShow ? '' : 'password'"
          autocomplete="new-password"
          placeholder="请输入登录密码"
        >
          <!-- <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" /> -->
          <AllowSvg slot="suffix" class="svg-icon" v-if="isShow" @click.stop="changeIsShow" />
          <NoallowSvg slot="suffix" class="svg-icon" v-else @click.stop="changeIsShow" />
        </a-input>
      </a-form-model-item>
      <!-- 确认密码 -->
      <a-form-model-item
        :rules="[
          { required: true, trigger: 'blur', validator: validateConfirmPass },
          { required: true, message: '请输入确认密码', trigger: 'blur' }
        ]"
        prop="confirmPassword"
      >
        <a-input
          v-model.trim="form.confirmPassword"
          ref="confirmPassword"
          :type="isShowConfirm ? '' : 'password'"
          placeholder="请输入确认密码"
          autocomplete="new-password"
        >
          <!-- <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" /> -->
          <AllowSvg slot="suffix" class="svg-icon" v-if="isShowConfirm" @click.stop="changeIsShowConfirm" />
          <NoallowSvg slot="suffix" class="svg-icon" v-else @click.stop="changeIsShowConfirm" />
        </a-input>
      </a-form-model-item>
      <!-- 公司名称 -->
      <a-form-model-item prop="company" :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]">
        <a-input type="text" placeholder="请输入公司名称" v-model="form.company"> </a-input>
      </a-form-model-item>
      <!-- 手机号码 -->
      <a-form-model-item
        prop="mobile"
        :rules="[
          {
            required: true,
            message: '请输入正确的手机号',
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ]"
      >
        <a-input type="text" placeholder="请输入手机号码" v-model="form.mobile"> </a-input>
      </a-form-model-item>
      <!-- 区域选择 -->
      <a-form-model-item prop="cur">
        <span style="margin-right: 10px">区域选择</span>
        <a-radio-group v-model="form.cur">
          <a-radio :value="0">中国大陆</a-radio>
          <a-radio :value="1">海外</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-button type="primary" class="btn" @click="handleSubmit" block>注册</a-button>
    </a-form-model>
    <div class="bottom-box">
      <a @click="skipToLogin">使用已有账号登录<a-icon type="right" /></a>
    </div>
  </div>
</template>

<script>
import AllowSvg from '@/assets/icons/allow.svg?inline'
import NoallowSvg from '@/assets/icons/noallow.svg?inline'
import { register } from '@/apiForManage/login'
import { Encrypt } from '@/utils/jsencrypt'
export default {
  name: 'Register',
  components: { AllowSvg, NoallowSvg },
  data () {
    return {
      form: {
        loginId: undefined,
        password: undefined,
        confirmPassword: undefined,
        company: undefined,
        mobile: undefined,
        cur: 0
      },
      isShow: false,
      isShowConfirm: false
    }
  },
  methods: {
    // 登录密码校验
    validatePass (rule, value, callback) {
      if (value === '' || value === undefined) {
        callback(new Error('请重新输入登录密码'))
      } else {
        if (value.length < 8) {
          callback(new Error('密码不低于8位'))
        } else {
          callback()
        }
      }
    },
    validateConfirmPass (rule, value, callback) {
      if (value === this.form.password) {
        callback()
      } else {
        callback(new Error('确认密码错误'))
      }
    },
    changeIsShow () {
      this.isShow = !this.isShow
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    changeIsShowConfirm () {
      this.isShowConfirm = !this.isShowConfirm
      this.$nextTick(() => {
        this.$refs.confirmPassword.focus()
      })
    },
    handleSubmit () {
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          const resp = await register({
            ...this.form,
            password: Encrypt(this.form.password),
            confirmPassword: Encrypt(this.form.confirmPassword),
            email: this.form.loginId
          })
          if (resp.code === 200) {
            this.$message.success('注册成功')
            this.$router.push({
              name: 'login'
            })
          }
        } else {
          return false
        }
      })
    },
    skipToLogin () {
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./user.less');
// .register-container {
// }
.has-error {
  .ant-form-explain {
    margin-left: 10px;
  }
}
.btn{
  padding: 0 15px;
  font-size: 16px;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 12px;
}
.bottom-box{
  display: flex;
  justify-content: center;
  margin-bottom: 28px;
}
</style>

/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-25 14:30:43
 * @FilePath: /apiForManage/login.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const userApi = {
  Login: '/user/login',
  Logout: '/user/logout',
  // get my info
  UserInfo: '/user/info',
  // UserMenu: '/role/getMenuList',
  GetCode: '/user/code',
  getMenuListByLoginId: '/user/getMenuListByLoginId'
}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login (parameter) {
  const req = request({
    url: userApi.Login,
    method: 'post',
    data: {
      ...parameter
      // ip: window.currentIp
    }
  })
  return req
}

export function getInfo () {
  return request({
    url: userApi.UserInfo,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function getCurrentUserNav (params) {
  return request({
    url: userApi.getMenuListByLoginId,
    method: 'get',
    params,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function logout () {
  return request({
    url: userApi.Logout,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: {
      // ip: window.currentIp
    }
  })
}

export function getCodeImg () {
  return request({
    url: userApi.GetCode,
    method: 'get'
  })
}
// 修改素材审核权限
export function updatePermission (data) {
  return request({
    url: `/user/updatePermission`,
    method: 'post',
    data
  })
}

// 注册
export function register (data) {
  return request({
    url: `/user/register`,
    method: 'post',
    data
  })
}
